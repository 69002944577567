<template>
  <div class="skin-exfoliants q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['hyperpigmentation'])">
      <single-answer-question
        title="label.hyperpigmentationToSoften"
        :value="hyperpigmentation"
        :options="$options.responseOptions"
        dysfunction="skin-pigmentation"
        yes-no
        @input="onFieldChange('hyperpigmentation', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'PigmentationTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  responseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    hyperpigmentation: {
      type: String,
      default: ''
    }
  }
};
</script>
