import PigmentationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/pigmentation/PigmentationTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinPigmentation/Pigmentation/PigmentationTemplate',
  component: PigmentationTemplate
};

const createStory = props => () => ({
  components: { PigmentationTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><pigmentation-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  hyperpigmentation: AMOUNT.NO
});
